/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const syncContractors = /* GraphQL */ `
  query SyncContractors(
    $filter: ModelContractorFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContractors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        email
        phoneNumber
        logoFileName
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        clients {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getContractor = /* GraphQL */ `
  query GetContractor($id: ID!) {
    getContractor(id: $id) {
      id
      name
      email
      phoneNumber
      logoFileName
      owner
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      clients {
        items {
          id
          name
          email
          phoneNumber
          clientStatus
          borrowingAmount
          projectStartDate
          estimatedCreditScore
          currentEmploymentStatus
          annualIncome
          monthlyHousingPayment
          contractorID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listContractors = /* GraphQL */ `
  query ListContractors(
    $filter: ModelContractorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContractors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        phoneNumber
        logoFileName
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        clients {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      name
      email
      phoneNumber
      clientStatus
      borrowingAmount
      projectStartDate
      estimatedCreditScore
      currentEmploymentStatus
      annualIncome
      monthlyHousingPayment
      contractorID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      contractor {
        id
        name
        email
        phoneNumber
        logoFileName
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        clients {
          nextToken
          startedAt
        }
      }
      owner
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        phoneNumber
        clientStatus
        borrowingAmount
        projectStartDate
        estimatedCreditScore
        currentEmploymentStatus
        annualIncome
        monthlyHousingPayment
        contractorID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        contractor {
          id
          name
          email
          phoneNumber
          logoFileName
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClients = /* GraphQL */ `
  query SyncClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        email
        phoneNumber
        clientStatus
        borrowingAmount
        projectStartDate
        estimatedCreditScore
        currentEmploymentStatus
        annualIncome
        monthlyHousingPayment
        contractorID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        contractor {
          id
          name
          email
          phoneNumber
          logoFileName
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        owner
      }
      nextToken
      startedAt
    }
  }
`;
