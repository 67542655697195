import React from "react"

import Layout from "../components/layout"
import Menu from "../components/menu"
import Footer from "../components/footer"
import DashContent from "../components/dash-content"
import Login from "./login"

import { withAuthenticator } from "aws-amplify-react"

const Dashboard = () => (
  <Layout type={1}>
    <Menu type={2} btype={2} />
    <DashContent />
    <Footer type={3} />
  </Layout>
)

export default withAuthenticator(Dashboard, false, [<Login />])
