import React, { useEffect, useState } from "react"
import { Link, navigate } from "gatsby"
import styles from "./dash-content.module.css"
import EP from "../images/EP.png"
import AC from "../images/AC.png"
import QC from "../images/QC.png"
import CS from "../images/CS.png"
import { Auth, API, graphqlOperation } from "aws-amplify"
import { listContractors } from "../graphql/queries.js"
import { createContractor } from "../graphql/mutations.js"

const DashContent = () => {
  const [cSite, setCSite] = useState("/404/")
  useEffect(() => {
    async function getCompanySite() {
      try {
        const anyUser = await Auth.currentAuthenticatedUser()
        console.log("On mount, getting user: ", anyUser)
        console.log("On mount, getting contractor with ID: ", anyUser?.username)

        const email = anyUser?.attributes?.email
        const companyName = anyUser?.attributes["custom:company_name"]
        console.log(
          `On mount, user email: ${email} and company name: ${companyName}`
        )

        const data = await API.graphql({
          query: listContractors,
          // variables: {
          //   ID: id,
          // },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        })
        console.log("On mount, fetched contractor data: ", data)

        if (data?.data?.listContractors?.items?.length === 0) {
          // Add Contractor Data to DynamoDB table
          console.log(
            "In dashboard mount, no contractor found - Now creating user in db..."
          )
          const newData = await API.graphql(
            graphqlOperation(createContractor, {
              input: {
                email: email,
                name: companyName,
              },
            })
          )
          console.log(
            "In dashboard mount, no contractor found - Finished Adding User to DB: ",
            newData
          )

          const dataItem = newData?.data?.createContractor
          const cSite = "/contractorSite/" + dataItem?.id
          console.log("On Mount, concatonated following site: ", cSite)

          setCSite(cSite)
        } else {
          const dataItem = data?.data?.listContractors?.items[0]
          const cSite = "/contractorSite/" + dataItem?.id
          console.log("On Mount, concatonated following site: ", cSite)

          setCSite(cSite)
        }
      } catch (e) {
        console.log(e)
        // navigate("/404/")
      }
    }
    getCompanySite()
  }, [])

  return (
    <div className={styles.container}>
      <h2 className={styles.titleHeading}>
        Welcome back, {Auth.user.attributes.name}
      </h2>
      <div className={styles.row}>
        {/* Left Column */}
        <div className={styles.leftColumn}>
          <Link to="/profile/">
            <div className={[styles.r1, styles.white].join(" ")}>
              <div className={styles.grid}>
                <div className={styles.content}>
                  <h1 className={styles.card_title}>Edit Profile</h1>
                  <h3 className={styles.pop_text}>
                    Make changes to your user account and public profile
                  </h3>
                  <div className={styles.button}>
                    <Link to="/profile/">
                      <div className={styles.button_text}>Edit Profile</div>
                    </Link>
                  </div>
                </div>
                <div className={styles.card_image}>
                  <img src={EP} className={styles.imgs} alt="Edit Profile" />
                </div>
              </div>
            </div>
          </Link>
          <Link to="/quote-calculator/">
            <div className={[styles.r1, styles.purple].join(" ")}>
              <div className={styles.grid}>
                <div className={styles.content}>
                  <h1 className={styles.card_title}>Monthly Estimate</h1>
                  <h3 className={styles.pop_text}>
                    Get a monthly interest estimate on your client's desired
                    loan
                  </h3>
                  <div className={styles.button}>
                    <Link to="/quote-calculator/">
                      <div className={styles.button_text}>Get Quote</div>
                    </Link>
                  </div>
                </div>
                <div className={styles.card_image}>
                  <img
                    src={QC}
                    className={styles.imgs}
                    alt="Monthly Etimates"
                  />
                </div>
              </div>
            </div>
          </Link>
        </div>

        {/* Right Column */}
        <div className={styles.rightColumn}>
          <Link to="/addClient/">
            <div className={[styles.r1, styles.yellow].join(" ")}>
              <div className={styles.grid}>
                <div className={styles.content}>
                  <h1 className={styles.card_title}>Add Client</h1>
                  <h3 className={styles.pop_text}>
                    Share your page to your client using email or phone
                  </h3>
                  <div className={styles.button}>
                    <Link to="/addClient/">
                      <div className={styles.button_text}>Add Client</div>
                    </Link>
                  </div>
                </div>
                <div className={styles.card_image}>
                  <img src={AC} className={styles.imgs} alt="Add Client" />
                </div>
              </div>
            </div>
          </Link>
          <a onClick={() => navigate(cSite)}>
            <div className={[styles.r1, styles.green].join(" ")}>
              <div className={styles.grid}>
                <div className={styles.content}>
                  <h1 className={styles.card_title}>Company Site</h1>
                  <h3 className={styles.pop_text}>
                    View your company website for the client questionaire
                  </h3>
                  <div className={styles.button}>
                    <a onClick={() => navigate(cSite)}>
                      <div className={styles.button_text}>Visit Site</div>
                    </a>
                  </div>
                </div>
              </div>
              <div className={styles.card_image}>
                <img src={CS} className={styles.imgs} alt="Company Site" />
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}

export default DashContent
